<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.manage-token')" />
    </ion-header>
    <ion-content>
      <BaseContent class="manage-token" :show-footer="false">
        <ion-segment
          :value="tokenBlockFilter"
          @ionChange="tokenBlockFilter = $event.target.value"
        >
          <ion-segment-button value="allToken">
            <ion-label v-text="$t('manage-token.all')" />
          </ion-segment-button>
          <ion-segment-button value="unpublishedToken">
            <ion-label v-text="$t('manage-token.unpublished')" />
          </ion-segment-button>
          <ion-segment-button value="promotedToken">
            <ion-label v-text="$t('manage-token.promoted-token')" />
          </ion-segment-button>
        </ion-segment>
        <ion-searchbar
          mode="ios"
          :placeholder="$t('manage-token.search-placeholder')"
          @ionChange="doSearch($event.target.value)"
        />
        <ion-list class="manage-token__list" ref="listItem">
          <TokenItem v-if="isLoading" :state-loading="isLoading" />

          <ion-item-sliding
            v-for="(token, index) in tokenList[tokenBlockFilter]"
            :key="`${index}-${tokenBlockFilter}`"
          >
            <ion-item-options side="start">
              <ion-item-option
                :color="token.published ? 'danger' : 'success'"
                @click="togglePublishToken(token)"
                v-text="
                  token.published
                    ? $t('manage-token.unpublish')
                    : $t('manage-token.publish')
                "
              />
              <ion-item-option
                v-if="token.published"
                color="warning"
                @click="togglePromoteToken(token)"
                v-text="
                  token.pushed
                    ? $t('manage-token.stop-promote')
                    : $t('manage-token.promote')
                "
              />
            </ion-item-options>
            <TokenItem
              :token="token"
              manage
              :checked="isChecked(token._id)"
              :checkbox="tokenBlockFilter === 'unpublishedToken'"
              :count="getVoteCountForToken(token)"
              @click="detailToken(token)"
              @check="checkToken($event.value, $event.token)"
            />
            <ion-item-options side="end">
              <ion-item-option
                color="dark"
                @click="deleteToken(token)"
                v-text="$t('global.delete')"
              />
            </ion-item-options>
          </ion-item-sliding>
          <p
            v-text="$t('global.empty')"
            v-if="
              (!tokenList ||
                !tokenList[tokenBlockFilter] ||
                tokenList[tokenBlockFilter].length === 0) &&
              !isLoading
            "
          />
        </ion-list>
      </BaseContent>
    </ion-content>

    <transition name="slide">
      <ion-footer v-if="tokenBlockFilter === 'unpublishedToken'">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                color="success"
                expand="block"
                @click="publishCheckedToken"
                v-text="$t('manage-token.publish')"
                :disabled="checkedToken.length === 0 || !checkedToken"
              />
            </ion-col>
            <ion-col>
              <ion-button
                color="danger"
                expand="block"
                @click="deleteCheckedToken"
                v-text="$t('global.delete')"
                :disabled="checkedToken.length === 0 || !checkedToken"
              />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </transition>
  </ion-page>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonSearchbar,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  loadingController,
} from '@ionic/vue';
import useStore from '@/store';
import { useRouter } from 'vue-router';
import { Token, TokenList } from '@/interfaces/token';
import { MutationTypes } from '@/store/modules/token/mutations';
import {
  ActionTypes as TokenAction,
  ActionTypes,
} from '@/store/modules/token/actions';
import { useVote } from '@/hooks/useVote';

export default defineComponent({
  name: 'ManageToken',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
    TokenItem,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonSearchbar,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const listItem = ref();
    const tokenBlockFilter = ref<
      'allToken' | 'unpublishedToken' | 'promotedToken'
    >('allToken');

    const tokenList: WritableComputedRef<TokenList | undefined> = computed(
      () => store.getters.tokenList
    );
    const checkedToken = ref<Token[]>([]);

    onMounted(() => {
      store.commit(MutationTypes.CURRENT_TOKEN, undefined);
      store.dispatch(ActionTypes.setTokenList);
    });

    const detailToken = async (currentToken: Token) => {
      await store.commit(MutationTypes.CURRENT_TOKEN, currentToken);
      await router.push('/detail-token');
    };

    const isChecked = (tokenId: string): boolean => {
      return checkedToken.value.some((token) => token._id === tokenId);
    };

    const checkToken = (value: boolean, token: Token) => {
      if (!value && token._id && isChecked(token._id)) {
        checkedToken.value = checkedToken.value.filter(
          (t) => t._id !== token._id
        );
      } else if (value && token._id && !isChecked(token._id)) {
        checkedToken.value.push(token);
      }
    };

    const togglePublishToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      const publishToken = { ...token };
      publishToken.published = !publishToken.published;
      await store.dispatch(TokenAction.updateToken, publishToken);
    };
    const togglePromoteToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      const pushToken = { ...token };
      pushToken.pushed = !pushToken.pushed;
      await store.dispatch(TokenAction.updateToken, pushToken);
    };
    const deleteToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      await store.dispatch(TokenAction.deleteToken, token);
    };

    const publishCheckedToken = async () => {
      const loading = await loadingController.create({ mode: 'ios' });
      await loading.present();

      checkedToken.value.forEach((token) => {
        togglePublishToken(token);
      });

      await loading.dismiss();
    };
    const deleteCheckedToken = async () => {
      const loading = await loadingController.create({ mode: 'ios' });
      await loading.present();

      checkedToken.value.forEach((token) => {
        deleteToken(token);
      });

      await loading.dismiss();
    };

    const doSearch = async (value: string) => {
      if (!tokenList.value || !tokenList.value[tokenBlockFilter.value]) return;

      if (!value || value.length === 0) {
        await store.dispatch(ActionTypes.setTokenList);
        return;
      }

      tokenList.value[tokenBlockFilter.value] = tokenList.value[
        tokenBlockFilter.value
      ].filter(
        (c: Token) =>
          c.label.toLowerCase().includes(value.toLowerCase()) ||
          c.description.toLowerCase().includes(value.toLowerCase()) ||
          c.symbol.toLowerCase().includes(value.toLowerCase()) ||
          c.chain.toLowerCase().includes(value.toLowerCase())
      );
    };

    return {
      isLoading,
      listItem,
      tokenBlockFilter,
      tokenList,
      checkedToken,

      doSearch,
      detailToken,
      isChecked,
      checkToken,
      togglePublishToken,
      togglePromoteToken,
      deleteToken,
      publishCheckedToken,
      deleteCheckedToken,
      getVoteCountForToken,
    };
  },
});
</script>

<style lang="scss" scoped>
.manage-token {
  &__list {
    margin-top: 1em;
    border-radius: var(--default-border-radius);
  }
}
</style>
