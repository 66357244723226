
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  WritableComputedRef,
} from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import TokenItem from '@/components/token-item/TokenItem.vue';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonSearchbar,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  loadingController,
} from '@ionic/vue';
import useStore from '@/store';
import { useRouter } from 'vue-router';
import { Token, TokenList } from '@/interfaces/token';
import { MutationTypes } from '@/store/modules/token/mutations';
import {
  ActionTypes as TokenAction,
  ActionTypes,
} from '@/store/modules/token/actions';
import { useVote } from '@/hooks/useVote';

export default defineComponent({
  name: 'ManageToken',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
    TokenItem,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonSearchbar,
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { getVoteCountForToken } = useVote();

    const isLoading = computed(() => store.getters.isLoading);

    const listItem = ref();
    const tokenBlockFilter = ref<
      'allToken' | 'unpublishedToken' | 'promotedToken'
    >('allToken');

    const tokenList: WritableComputedRef<TokenList | undefined> = computed(
      () => store.getters.tokenList
    );
    const checkedToken = ref<Token[]>([]);

    onMounted(() => {
      store.commit(MutationTypes.CURRENT_TOKEN, undefined);
      store.dispatch(ActionTypes.setTokenList);
    });

    const detailToken = async (currentToken: Token) => {
      await store.commit(MutationTypes.CURRENT_TOKEN, currentToken);
      await router.push('/detail-token');
    };

    const isChecked = (tokenId: string): boolean => {
      return checkedToken.value.some((token) => token._id === tokenId);
    };

    const checkToken = (value: boolean, token: Token) => {
      if (!value && token._id && isChecked(token._id)) {
        checkedToken.value = checkedToken.value.filter(
          (t) => t._id !== token._id
        );
      } else if (value && token._id && !isChecked(token._id)) {
        checkedToken.value.push(token);
      }
    };

    const togglePublishToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      const publishToken = { ...token };
      publishToken.published = !publishToken.published;
      await store.dispatch(TokenAction.updateToken, publishToken);
    };
    const togglePromoteToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      const pushToken = { ...token };
      pushToken.pushed = !pushToken.pushed;
      await store.dispatch(TokenAction.updateToken, pushToken);
    };
    const deleteToken = async (token: Token) => {
      await listItem.value.$el.closeSlidingItems();
      await store.dispatch(TokenAction.deleteToken, token);
    };

    const publishCheckedToken = async () => {
      const loading = await loadingController.create({ mode: 'ios' });
      await loading.present();

      checkedToken.value.forEach((token) => {
        togglePublishToken(token);
      });

      await loading.dismiss();
    };
    const deleteCheckedToken = async () => {
      const loading = await loadingController.create({ mode: 'ios' });
      await loading.present();

      checkedToken.value.forEach((token) => {
        deleteToken(token);
      });

      await loading.dismiss();
    };

    const doSearch = async (value: string) => {
      if (!tokenList.value || !tokenList.value[tokenBlockFilter.value]) return;

      if (!value || value.length === 0) {
        await store.dispatch(ActionTypes.setTokenList);
        return;
      }

      tokenList.value[tokenBlockFilter.value] = tokenList.value[
        tokenBlockFilter.value
      ].filter(
        (c: Token) =>
          c.label.toLowerCase().includes(value.toLowerCase()) ||
          c.description.toLowerCase().includes(value.toLowerCase()) ||
          c.symbol.toLowerCase().includes(value.toLowerCase()) ||
          c.chain.toLowerCase().includes(value.toLowerCase())
      );
    };

    return {
      isLoading,
      listItem,
      tokenBlockFilter,
      tokenList,
      checkedToken,

      doSearch,
      detailToken,
      isChecked,
      checkToken,
      togglePublishToken,
      togglePromoteToken,
      deleteToken,
      publishCheckedToken,
      deleteCheckedToken,
      getVoteCountForToken,
    };
  },
});
